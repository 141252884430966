export class UserSimulation {
    id?: number;
    name?: string;
    cpf?: string;
    cellphone?: string;
    email?: string;
    birthday?: string;
    uf?: string;
    profileImage?: string;
    plate?: string;
    benefitNumber?: string;
    benefitType?: number;
    agency?: string;
    valueMargemInss?: number;
    numeroBeneficioAuxB?: string;
    valueMargemAuxB?: number;
    installments?: number;
    since?: string;
    products?: string[];
    proposal?: any;
    personalData?: any;
    addressData?: any;
    bankData?: any;
    tcExemptionAnalyze?: boolean;
    tcExemption?: boolean;
    tcFee?: boolean;
    benefitUfCredcesta?: string;
    benefitNumberCredcesta?: string;
    benefitTypeCredcesta?: number;
    agencyCredcesta?: string;
    covenantIdCredcesta?: string;
    valueMargemCredcesta?: number;
    installmentsCredcesta?: number;
    cpfRepresentanteCredcesta?: string;
    receiptFormCredcesta?: string;
    cep?: string;
    state?: string;
    city?: string;
    readDate?: number;
    consumerUnity?: string;
    valueRequested?: number;
    typist?: number;
    occupationId?: number;
    saleOriginId?: number;
    dadosBancarios?: any;

    constructor(
        {
            id = 0,
            name = '',
            cpf = '',
            cellphone = '',
            email = '',
            birthday = '',
            uf = '',
            profileImage = '',
            plate = '',
            benefitNumber = '',
            benefitType = 0,
            agency = '',
            valueMargemInss = 0,
            numeroBeneficioAuxB = '',
            valueMargemAuxB = 0,
            installments = 0,
            since = '',
            products = [],
            proposal = {},
            personalData = {},
            addressData = {},
            bankData = {},
            tcExemptionAnalyze,
            tcExemption = true,
            tcFee = false,
            benefitUfCredcesta = '',
            benefitNumberCredcesta = '',
            benefitTypeCredcesta = 0,
            agencyCredcesta = '',
            covenantIdCredcesta = '',
            valueMargemCredcesta = 0,
            installmentsCredcesta = 0,
            receiptFormCredcesta = '',
            cpfRepresentanteCredcesta = '',
            cep = '',
            state = '',
            city = '',
            readDate = 0,
            consumerUnity = '',
            valueRequested = 0,
            typist = 0,
            occupationId = 0,
            saleOriginId = 0
        }: any) {
        this.id = id;
        this.name = name;
        this.cpf = cpf;
        this.cellphone = cellphone;
        this.email = email;
        this.birthday = birthday;
        this.uf = uf;
        this.profileImage = profileImage;
        this.plate = plate;
        this.benefitNumber = benefitNumber;
        this.benefitType = benefitType;
        this.agency = agency;
        this.valueMargemInss = valueMargemInss;
        this.numeroBeneficioAuxB = numeroBeneficioAuxB;
        this.valueMargemAuxB = valueMargemAuxB;
        this.installments = installments;
        this.since = since;
        this.products = products;
        this.proposal = proposal;
        this.personalData = personalData;
        this.addressData = addressData;
        this.bankData = bankData;
        this.tcExemptionAnalyze = tcExemptionAnalyze;
        this.tcExemption = tcExemption;
        this.tcFee = tcFee;
        this.benefitUfCredcesta = benefitUfCredcesta;
        this.benefitNumberCredcesta = benefitNumberCredcesta;
        this.benefitTypeCredcesta = benefitTypeCredcesta;
        this.agencyCredcesta = agencyCredcesta;
        this.covenantIdCredcesta = covenantIdCredcesta;
        this.valueMargemCredcesta = valueMargemCredcesta;
        this.installmentsCredcesta = installmentsCredcesta;
        this.receiptFormCredcesta = receiptFormCredcesta;
        this.cpfRepresentanteCredcesta = cpfRepresentanteCredcesta;
        this.cep = cep;
        this.state = state;
        this.city = city;
        this.readDate = readDate;
        this.consumerUnity = consumerUnity;
        this.valueRequested = valueRequested;
        this.typist = typist;
        this.occupationId = occupationId;
        this.saleOriginId = saleOriginId;
    }
}
